import React, { useState } from "react";

export default function AdsArchive() {
    const [currentDialogIndex, setCurrentDialogIndex] = useState(0);

    const dialogs = [
        {
            title: "Apply for Ph.D. Programs",
            content: (
                <img
                    src="images/phd_admission.jpg"
                    alt="PhD"
                    style={{
                        // objectFit: "fit",
                        maxWidth: "100%",
                        marginBottom: "20px",
                        marginRight: "20px",
                    }}
                />
            ),
        },
        {
            title: "Apply for Associate/Assistant Professor",
            content: (
                <img
                    src="images/poster_faculty.jpeg"
                    alt="Faculty"
                    style={{
                        // objectFit: "fit",
                        maxWidth: "100%",
                        marginBottom: "20px",
                        marginRight: "20px",
                    }}
                />
            ),
        },
    ];

    return (
        <>
            <section
                className="section"
                style={{ position: "relative", top: "185px", marginBottom: "100px", padding: "50px 20px" }}
            >
                <center>
                    <h2>Advertisements Archive</h2>
                </center>
                <p
                    style={{
                        textAlign: "justify",
                        fontSize: "1.2em",
                        padding: "20px",
                        marginBottom: "120px",
                    }}
                >
                    Explore current and past advertisements related to academic openings and
                    program admissions at JSS University, Noida.
                </p>
            </section>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {dialogs.map((ad, i) => (
                    <div
                        key={i}
                        style={{
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            padding: "1rem",
                            maxWidth: "800px",
                            display: "flex",
                            flexDirection: "column",
                            margin: "10px 20px",
                        }}
                    >
                        <h3>{ad.title}</h3>
                        {ad.content}
                    </div>
                ))}
            </div>
        </>
    );
}