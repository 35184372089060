import React, { useState } from "react";

export default function UniversityNewsLetter() {
    const [showArchives, setShowArchives] = useState(false);

    return (
        <>
            <section
                className="section"
                style={{
                    position: "relative",
                    top: "180px",
                    marginBottom: "100px",
                    padding: "50px 20px",
                }}
            >
                <center>
                    <h1>JSS University Noida News Letters</h1>
                </center>

                <div style={{ margin: "20px" }}>
                    <center>
                        <a
                            class="btn-primary btn blinking-btn" style={{ margin: "20px" }} target="_blank"
                            href="https://jssuninoida.edu.in/assets/docs/JSS-University-Noida-NewsletterVol2.pdf"
                        >
                            University Newsletter VOL 2
                        </a><br /><br />
                        <a
                            class="btn-primary btn" target="_blank" style={{ margin: "20px" }}
                            href="https://jssuninoida.edu.in/assets/docs/University_News_Letter_Vol1.pdf"
                        >
                            University Newsletter VOL 1
                        </a>
                    </center>
                </div><br /><br />

                {/* <div style={{ marginBottom: "100px", marginLeft: "20px" }}>
                <h3>Previous Years</h3>
                    <button onClick={() => setShowArchives(!showArchives)} className="btn-primary btn">
                        {showArchives ? "Hide" : "Show"} Archives
                    </button>
                    {showArchives && (
                        <div style={{ marginTop: "20px" }}>
                            <h3>No Data Available</h3>
                        </div>
                    )}
                </div> */}
            </section>
        </>
    );
}