import React, { useEffect, useState } from "react";
import Notification from "./Homepage/components/Notifications";

export default function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleDropdown = (event, level) => {
    if (isMobile) {
      event.preventDefault();
      const currentTarget = event.currentTarget;
      const submenu = currentTarget.nextElementSibling;

      if (submenu) {
        const isCurrentlyActive = submenu.classList.contains("show");

        // Close all other open dropdowns at the same level
        const allDropdowns = document.querySelectorAll(
          `.dropdown-menu.show.level-${level}`
        );
        allDropdowns.forEach((dropdown) => {
          if (dropdown !== submenu) {
            dropdown.classList.remove("show");
          }
        });

        // Toggle the clicked dropdown
        submenu.classList.toggle("show");

        // If this dropdown was just opened, close any open child dropdowns
        if (!isCurrentlyActive) {
          const childDropdowns = submenu.querySelectorAll(
            ".dropdown-menu.show"
          );
          childDropdowns.forEach((childDropdown) => {
            childDropdown.classList.remove("show");
          });
        }
      }
    }
  };
  return (
    <>
      <div
        style={{
          position: "relative",
          top: windowWidth > 768 ? "50px" : "0px",
        }}
      >
        <header class="fixed-top header">
          <Notification></Notification>
          {windowWidth > 768 ? (
            <div class="top-header py-2 bg-white">
              <div class="container">
                <div class="row no-gutters">
                  <div class="col-lg-4 text-center text-lg-left">
                    <a class="text-color mr-3" href="tel:+9101202401442">
                      <strong>CALL</strong> +91-0120-2401442
                    </a>
                    <ul class="list-inline d-inline">
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-twitter-alt"></i>
                        </a>
                      </li>
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-linkedin"></i>
                        </a>
                      </li>
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="col-lg-8 text-center text-lg-right"
                    style={{ float: "right" }}
                  >
                    <marquee>
                      <a
                        class="text-uppercase p-sm-2 py-2 px-0 d-inline-block"
                        href="https://jssuninoida.edu.in/assets/docs/next_sem_plan_vc.pdf"
                        target="_blank"
                        style={{ color: "#fff", backgroundColor: "crimson" }}
                      >
                        Expression of Gratitude and Plans for Upcoming Semester
                      </a>
                    </marquee>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              class="py-2 bg-white"
              style={{
                minHeight: "15px",
              }}
            >
              <div class="container">
                <div class="row no-gutters">
                  <div class="col-lg-4 text-center text-lg-left">
                    <a class="text-color mr-3" href="tel:+9101202401442">
                      <strong>CALL</strong> +91-0120-2401442
                    </a>
                    <ul class="list-inline d-inline">
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-twitter-alt"></i>
                        </a>
                      </li>
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-linkedin"></i>
                        </a>
                      </li>
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="col-lg-8 text-center text-lg-right"
                    style={{ float: "right" }}
                  >
                    <marquee>
                      <a
                        class="text-uppercase p-sm-2 py-2 px-0 d-inline-block"
                        href="https://jssuninoida.edu.in/assets/docs/next_sem_plan_vc.pdf"
                        target="_blank"
                        style={{ color: "#fff", backgroundColor: "crimson" }}
                      >
                        Expression of Gratitude and Plans for Upcoming Semester
                      </a>
                    </marquee>
                  </div>
                </div>
              </div>
            </div>
          )}
          {windowWidth > 768 ? (
            <div class="navigation w-100">
              {/* <div class="container"> */}
              <nav
                class="navbar navbar-expand-lg navbar-light p-0"
                style={{ height: "120px" }}
              >
                <a
                  style={{
                    maxWidth: "35%",
                    color: "#fff",
                    fontWeight: "bolder",
                    padding: "5px 30px",
                    fontSize: "1.3em",
                  }}
                  href="/"
                  className="navbar-brand"
                >
                  <img
                    src="./images/logo.png"
                    alt="logo"
                    style={{
                      maxHeight: "85px",
                      maxWidth: "85px",
                      margin: "0px 15px",
                      // position: "relative",
                      // left:"100px",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      lineHeight: "1em",
                      verticalAlign: "middle",
                    }}
                  >
                    JSS
                    <br />
                    UNIVERSITY
                  </div>
                </a>

                <button
                  class="navbar-toggler rounded-0"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navigation"
                  aria-controls="navigation"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div
                  class="collapse navbar-collapse"
                  style={{
                    // position: "relative",
                    // right:"150px"
                    fontSize: "1.2em",
                  }}
                  id="navigation"
                >
                  <ul class="navbar-nav mr -auto text-center">
                    <li class="nav-item dropdown view" id="myDropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-toggle="dropdown"
                      >
                        {" "}
                        About{" "}
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          {" "}
                          <a class="dropdown-item" href="/about-jssmvp">
                            {" "}
                            About JSSMVP
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="/about-university">
                            {" "}
                            About University
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="/vision-mission-quality-policy"
                          >
                            {" "}
                            Vision and Mission, Quality Policy
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="#">
                            {" "}
                            Leadership &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="/chancellor">
                                Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/executive-seceratory"
                              >
                                Executive Secretary
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/about-pro-chancellor"
                              >
                                Pro Chancellor
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            data-toggle="dropdown"
                          >
                            {" "}
                            Authorities &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="/governing-bodies">
                                Governing Body (GB)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/home#"
                              >
                                Executive Council (EC)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/home#"
                              >
                                Academic Council
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="https://jssuninoida.edu.in/assets/docs/BOS.pdf"
                                target="_blank"
                              >
                                Board of Studies (BoS)
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          {" "}
                          <a class="dropdown-item" href="#">
                            {" "}
                            Administration &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/administration-vice-chancellor"
                              >
                                Vice Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/registrar-message"
                              >
                                Registrar
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/controller-of-examination"
                              >
                                Controller of Examination (CoE)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Deans
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Special Officer
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Financial Officer
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            Committee &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/ant_ragging_committe.pdf"
                              >
                                Anti Ragging Committee
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/Student_Counselling_Cell.pdf"
                              >
                                Student Counselling Cell
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/grievance_redressal.pdf"
                              >
                                Grievance Redressal Cell
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/ICC.pdf"
                              >
                                ICC
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/SC_ST_OBC.pdf"
                              >
                                ST, SC, OBC
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          {" "}
                          <a class="dropdown-item" href="#">
                            {" "}
                            Reports and Downloads &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                University Approval Letter
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="#">
                            {" "}
                            University Green Initiatives &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="/green-policy">
                                Green Policy
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/sewage-treatment-plant"
                              >
                                Sewage Treatment Plant (STP)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/rooftop-solar-plant"
                              >
                                Roof Top Solar Power Plant
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/rainwater-harvesting"
                              >
                                Rainwater Harvesting
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/sustainable-waste-management"
                              >
                                Sustainable Solid Waste Management
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/tree-plantation">
                                Gardening & Tree Planting Information
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="/employee-benefits">
                            {" "}
                            Employee Benefits
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/statutory-bodies">
                            Approval from Statutory bodies
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="#">
                            {" "}
                            Regulations &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/regulation_btech.pdf"
                              >
                                B.Tech Regulations
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/regulation_mca.pdf"
                              >
                                MCA Regulations
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/regulation_bpharma.pdf"
                              >
                                B.Pharma Regulations
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/regulation_dpharma.pdf"
                              >
                                D.Pharma Regulations
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/Mandatory_Disclosure.pdf"
                            target="_blank"
                          >
                            Mandatory Disclosure
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="#">
                            {" "}
                            DST Project &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/dst_project_EOI.pdf"
                              >
                                EOI
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/dst_project_tender_SEED_WS_2023_643.pdf"
                              >
                                Tender Document
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Admission
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a
                            class="dropdown-item"
                            href="/school-of-engineering"
                          >
                            Under Graduate Program &raquo;
                          </a>
                          {/* <ul class="submenu dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in COMPUTER SCIENCE AND ENGINEERING</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in COMPUTER SCIENCE AND ENGINEERING (ARTIFICIAL INTELLIGENCE AND MACHINE LEARNING)</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in COMPUTER SCIENCE AND ENGINEERING (DATA SCIENCE)</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in INFORMATION TECHNOLOGY</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in ELECTRONICS AND COMMUNICATION ENGINEERING</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in ROBOTICS AND ARTIFICIAL INTELLIGENCE</a>
                          </li>
                        </ul> */}
                        </li>
                        <li>
                          <a class="dropdown-item" href="/school-of-management">
                            Post Graduate Program &raquo;
                          </a>
                        </li>
                        {/* <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Research Program
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Prospectus
                          </a>
                        </li> */}
                        <li>
                          <a
                            class="dropdown-item"
                            href="/admission-helpdesk-contact"
                          >
                            Admission Help Desk & Contact Details
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-toggle="dropdown"
                      >
                        Academics
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="#">
                            Faculty &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/faculty-of-engg-tech"
                              >
                                Faculty of Engineering & Technology
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/faculty-of-science"
                              >
                                Faculty of Science
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/faculty-of-humanities"
                              >
                                Faculty of Humanities
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Faculty of Management Studies
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="https://jssaten.ac.in/pview/pharmacy"
                              >
                                Faculty of Pharmacy
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/calendar-of-events">
                            Calendar of Events
                          </a>
                          {/* <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item" target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/JSSUN-2024-1st-Sem-Calendar-of%20Events-V2.pdf"
                              >
                                Calendar of Events 2024-25, UG : Odd Sem
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item" target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/JSSUN-Calender-Events-2024-25-Even-Sem.pdf"
                              >
                                Calendar of Events 2024-25, UG : Even Sem
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                              >
                                Archives
                              </a>
                            </li>
                          </ul> */}
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            Syllabus & Schemes &raquo;
                          </a>
                          {/* <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/JSS_University_Noida_B_Tech_Scheme_&_Syllabus.pdf"
                              >
                                B.Tech Syllabus & Schemes
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/JSS_University_Noida_MCA_Scheme_&_Syllabus.pdf"
                              >
                                MCA Syllabus & Schemes
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/JSS_University_Noida_B.Pharm_Scheme_&_Syllabus.pdf"
                              >
                                B.Pharma Syllabus & Schemes
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/JSS_University_Noida_D.Pharma_Scheme_&_Syllabus.pdf"
                              >
                                D.Pharma Syllabus & Schemes
                              </a>
                            </li>
                          </ul> */}
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Exam Schedule
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/TIME_TABLE.pdf"
                            target="_blank"
                          >
                            Time Table
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            Professional Bodies &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="/csi-detail">
                                CSI
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/sae-detail">
                                SAE
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/iete-detail">
                                IETE
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/iste-detail">
                                ISTE
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/ieee-detail">
                                IEEE
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Industry Collaboration & MoUs
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/university-news-letter"
                          >
                            University News Letter
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-toggle="dropdown"
                      >
                        Examination
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="/notifications">
                            Notifications / Announcements
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/examination-circulars"
                          >
                            Examination Circulars
                          </a>
                        </li>
                        <li class="dropdown-submenu">
                          <a class="dropdown-item" href="#">
                            Examination Fee &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a class="dropdown-item" target="_blank" href="#">
                                Diploma
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" target="_blank" href="#">
                                UG
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" target="_blank" href="#">
                                PG
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" target="_blank" href="#">
                                PH.D
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li class="dropdown-submenu">
                          <a class="dropdown-item" href="#">
                            Examination Regulations &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li class="dropdown-submenu">
                              <a class="dropdown-item" href="#">
                                School of Engineering &raquo;
                              </a>
                              <ul class="submenu dropdown-menu">
                                <li>
                                  <a
                                    class="dropdown-item"
                                    target="_blank"
                                    href="https://jssuninoida.edu.in/assets/docs/Rev_05_JSS_University_Noida_B._Tech_Examination_regulations.pdf"
                                  >
                                    B. TECH
                                  </a>
                                </li>
                              </ul>
                            </li>

                            <li class="dropdown-submenu">
                              <a class="dropdown-item" href="#">
                                School of Pharmacy &raquo;
                              </a>
                              <ul class="submenu dropdown-menu">
                                <li>
                                  <a
                                    class="dropdown-item"
                                    target="_blank"
                                    href="https://jssuninoida.edu.in/assets/docs/Rev_02_JSS_University_Noida_B.Pharma_Examination_regulations.pdf"
                                  >
                                    B.PHARM
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    target="_blank"
                                    href="https://jssuninoida.edu.in/assets/docs/Rev_00_JSS_University_Noida_D.Pharma_Examination_regulations.pdf"
                                  >
                                    D.PHARM
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    target="_blank"
                                    href="#"
                                  >
                                    M.PHARM
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li class="dropdown-submenu">
                              <a class="dropdown-item" href="#">
                                School of Commerce and Management Studies
                                &raquo;
                              </a>
                              <ul class="submenu dropdown-menu">
                                <li>
                                  <a
                                    class="dropdown-item"
                                    target="_blank"
                                    href="https://jssuninoida.edu.in/assets/docs/Rev_01_JSS_University_Noida_MCA_Examination_regulations.pdf"
                                  >
                                    MCA
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                PH. D
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            NAD
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            ABC ID
                          </a>
                        </li>
                        <li class="dropdown-submenu">
                          <a class="dropdown-item" href="#">
                            Results &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                href="https://studentportal.universitysolutions.in/index.html"
                                target="_blank"
                              >
                                Result
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="https://jssuninoida.edu.in/assets/docs/EXAM_GRIVENCE_FORM.docx"
                                target="_blank"
                              >
                                Examination Grievance Form
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Examination manual
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Convocation
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Contact us
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Facilities
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a
                          class="dropdown-item"
                          href="/library-information-centre"
                        >
                          Library & Information Centre
                        </a>
                        <a class="dropdown-item" href="/hostel-facilities">
                          Hostel
                        </a>
                        <a class="dropdown-item" href="/sports-facilities">
                          Sports
                        </a>
                        <a class="dropdown-item" href="/medical-facilities">
                          Medical Centre
                        </a>
                        <a class="dropdown-item" href="/amenities-center">
                          Amenities Centre
                        </a>
                        <a class="dropdown-item" href="/guest-house">
                          Guest House
                        </a>
                        <a class="dropdown-item" href="/bank-atm">
                          Bank ATM
                        </a>
                        <a class="dropdown-item" href="/canteen">
                          Canteen
                        </a>
                      </div>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Student Support
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/scholarships">
                          Scholarships
                        </a>
                        <a class="dropdown-item" href="/mentoring">
                          Mentoring Scheme
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Fee Payment
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          NPTEL
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Infosys Springboard
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Cultural Team
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Student Clubs
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Accidental Insurance Scheme
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Meritortious Awards
                        </a>
                      </div>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Research & Innovation
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/PhD_Admission_Notification.pdf"
                            target="_blank"
                          >
                            PhD Admission Notification
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/PhD_REGULATIONS.pdf"
                            target="_blank"
                          >
                            PhD Regulation
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/PhD_Guides_List.pdf"
                            target="_blank"
                          >
                            PhD Guide List
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/Phd_Research_Form_3.pdf"
                            target="_blank"
                          >
                            Form for Guide/Co-Guide (Form-3)
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/PhD_Research_Form_1.pdf"
                            target="_blank"
                          >
                            Form for Admission to PhD Program (Form-1)
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/research-facilities">
                            Research Centres and Facilities
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/step-info">
                            STEP and Startups in Campus
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link "
                        href="/placement-statistics"
                        role="button"
                        // data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        Training & Placement
                      </a>
                      {/* <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/page-under-development">
                          Placement Team
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Placement Policy
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Placement Statistics
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Training
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Internship
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Contact Form for Companies
                        </a>
                      </div> */}
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        More
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a class="dropdown-item" href="/iqac">
                            IQAC
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/photo-gallery">
                            Photo Gallery
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://forms.gle/dsApqfUTFfht5G7R7"
                            target="_blank"
                          >
                            Online Grievance
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/media-coverage">
                            Media Coverage
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Career Oppurtunity
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/ads-archive">
                            Ads Archives
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link"
                        href="/admission-helpdesk-contact"
                        role="button"
                        // data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* </div> */}
            </div>
          ) : (
            <div
              class="navigation w-100"
              style={{
                marginTop: "-46px !important",
              }}
            >
              {/* <div class="container"> */}
              <nav class="navbar navbar-expand-lg navbar-light p-0">
                <a
                  style={{
                    maxWidth: "30%",
                    color: "#fff",
                    fontWeight: "bolder",
                    padding: "5px 30px",
                  }}
                  href="/"
                  className="navbar-brand"
                >
                  <img
                    src="./images/logo.png"
                    alt="logo"
                    style={{
                      maxHeight: "60px",
                      maxWidth: "60px",
                      margin: "0px 15px",
                      // position: "relative",
                      // left:"100px",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      lineHeight: "1em",
                      verticalAlign: "middle",
                    }}
                  >
                    JSS
                    <br />
                    UNIVERSITY
                  </div>
                </a>

                <button
                  class="navbar-toggler rounded-0"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navigation"
                  aria-controls="navigation"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div
                  class="collapse navbar-collapse"
                  style={
                    {
                      // position: "relative",
                      // right:"150px"
                    }
                  }
                  id="navigation"
                >
                  <ul class="navbar-nav mr -auto text-center">
                    <li class="nav-item dropdown view" id="myDropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-toggle="dropdown"
                        onClick={(e) => toggleDropdown(e, 1)}
                      >
                        {" "}
                        About{" "}
                      </a>
                      <ul class="dropdown-menu level-1">
                        <li>
                          {" "}
                          <a class="dropdown-item" href="/about-jssmvp">
                            {" "}
                            About JSSMVP
                          </a>
                        </li>
                        <li>
                          <li>
                            {" "}
                            <a class="dropdown-item" href="/about-university">
                              {" "}
                              About University
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a
                              class="dropdown-item"
                              href="/vision-mission-quality-policy"
                            >
                              {" "}
                              Vision and Mission, Quality Policy
                            </a>
                          </li>{" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            Leadership &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a class="dropdown-item" href="/chancellor">
                                Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/executive-seceratory"
                              >
                                Executive Secretary
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/about-pro-chancellor"
                              >
                                Pro Chancellor
                              </a>
                            </li>
                          </ul>
                        </li>
                        {/* <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="/about-university"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            Messages &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/chancellor-message"
                              >
                                Message from Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/pro-chancellor-message"
                              >
                                Message from Pro Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/vice-chancellor-message"
                              >
                                Message from Vice Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/registrar-message"
                              >
                                Message from Registrar
                              </a>
                            </li>
                          </ul>
                        </li> */}

                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            data-toggle="dropdown"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            Authorities &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a class="dropdown-item" href="/governing-bodies">
                                Governing Body (GB)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/home#"
                              >
                                Executive Council (EC)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/home#"
                              >
                                Academic Council
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="https://jssuninoida.edu.in/assets/docs/BOS.pdf"
                                target="_blank"
                              >
                                Board of Studies (BoS)
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            Administration &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/administration-vice-chancellor"
                              >
                                Vice Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/registrar-message"
                              >
                                Registrar
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/controller-of-examination"
                              >
                                Controller of Examination (CoE)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Deans
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Special Officer
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Financial Officer
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            Committee &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/ant_ragging_committe.pdf"
                              >
                                Anti Ragging Committee
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/Student_Counselling_Cell.pdf"
                              >
                                Student Counselling Cell
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/grievance_redressal.pdf"
                              >
                                Grievance Redressal Cell
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/ICC.pdf"
                              >
                                ICC
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/SC_ST_OBC.pdf"
                              >
                                ST, SC, OBC
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            Reports and Downloads &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                University Approval Letter
                              </a>
                            </li>
                            {/* <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                University Acts, Ordinances and Statutes
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                University Propectus
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Human Resource Mannual
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                University Annual Reports
                              </a>
                            </li> */}
                          </ul>
                        </li>

                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            University Green Initiatives &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a class="dropdown-item" href="/green-policy">
                                Green Policy
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/sewage-treatment-plant"
                              >
                                Sewage Treatment Plant (STP)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/rooftop-solar-plant"
                              >
                                Roof Top Solar Power Plant
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/rainwater-harvesting"
                              >
                                Rainwater Harvesting
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/sustainable-waste-management"
                              >
                                Sustainable Solid Waste Management
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/tree-plantation">
                                Gardening & Tree Planting Information
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="/employee-benefits">
                            {" "}
                            Employee Benefits
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/statutory-bodies">
                            Approval from Statutory bodies
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            Regulations &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/regulation_btech.pdf"
                              >
                                B.Tech Regulations
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/regulation_mca.pdf"
                              >
                                MCA Regulations
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/regulation_bpharma.pdf"
                              >
                                B.Pharma Regulations
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/regulation_dpharma.pdf"
                              >
                                D.Pharma Regulations
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/Mandatory_Disclosure.pdf"
                            target="_blank"
                          >
                            Mandatory Disclosure
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            DST Project &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/dst_project_EOI.pdf"
                              >
                                EOI
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/dst_project_tender_SEED_WS_2023_643.pdf"
                              >
                                Tender Document
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Admission
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a
                            class="dropdown-item"
                            href="/school-of-engineering"
                          >
                            Under Graduate Program &raquo;
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/school-of-management">
                            Post Graduate Program &raquo;
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/admission-helpdesk-contact"
                          >
                            Admission Help Desk & Contact Details
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-toggle="dropdown"
                        onClick={(e) => toggleDropdown(e, 1)}
                      >
                        Academics
                      </a>
                      <ul class="dropdown-menu level-1">
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            Faculty &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/faculty-of-engg-tech"
                              >
                                Faculty of Engineering & Technology
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/faculty-of-science"
                              >
                                Faculty of Science
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/faculty-of-humanities"
                              >
                                Faculty of Humanities
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Faculty of Management Studies
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="https://jssaten.ac.in/pview/pharmacy"
                              >
                                Faculty of Pharmacy
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/calendar-of-events">
                            Calendar of Events
                          </a>
                          {/* <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item" target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/JSSUN-2024-1st-Sem-Calendar-of%20Events-V2.pdf"
                              >
                                Calendar of Events 2024-25, UG : Odd Sem
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item" target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/JSSUN-Calender-Events-2024-25-Even-Sem.pdf"
                              >
                                Calendar of Events 2024-25, UG : Even Sem
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                              >
                                Archives
                              </a>
                            </li>
                          </ul> */}
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            Syllabus & Schemes &raquo;
                          </a>
                          {/* <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/JSS_University_Noida_B_Tech_Scheme_&_Syllabus.pdf"
                              >
                                B.Tech Syllabus & Schemes
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/JSS_University_Noida_MCA_Scheme_&_Syllabus.pdf"
                              >
                                MCA Syllabus & Schemes
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/JSS_University_Noida_B.Pharm_Scheme_&_Syllabus.pdf"
                              >
                                B.Pharma Syllabus & Schemes
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://jssuninoida.edu.in/assets/docs/JSS_University_Noida_D.Pharma_Scheme_&_Syllabus.pdf"
                              >
                                D.Pharma Syllabus & Schemes
                              </a>
                            </li>
                          </ul> */}
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Exam Schedule
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/TIME_TABLE.pdf"
                            target="_blank"
                          >
                            Time Table
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            Professional Bodies &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a class="dropdown-item" href="/csi-detail">
                                CSI
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/sae-detail">
                                SAE
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/iete-detail">
                                IETE
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/iste-detail">
                                ISTE
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/ieee-detail">
                                IEEE
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Industry Collaboration & MoUs
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/university-news-letter"
                          >
                            University News Letter &raquo;
                          </a>
                          {/* <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a class="dropdown-item" href="https://jssuninoida.edu.in/assets/docs/University_News_Letter_Vol1.pdf" target="_blank">
                                Volume 1 (July 2024 - December 2024)
                              </a>
                            </li>
                          </ul> */}
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-toggle="dropdown"
                        onClick={(e) => toggleDropdown(e, 1)}
                      >
                        Examination
                      </a>
                      <ul class="dropdown-menu level-1">
                        <li>
                          <a
                            class="dropdown-item"
                            href="/notifications"
                          >
                            Notifications / Announcements
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/examination-circulars"
                          >
                            Examination Circulars
                          </a>
                        </li>
                        <li class="dropdown-submenu">
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            Examination Fee
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a class="dropdown-item" href="#">
                                Diploma
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                UG
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                PG
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                PH.D
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li class="dropdown-submenu">
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            Examination Regulations
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li class="dropdown-submenu">
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={(e) => toggleDropdown(e, 3)}
                              >
                                School of Engineering
                              </a>
                              <ul class="submenu dropdown-menu level-3">
                                <li>
                                  <a class="dropdown-item" href="#">
                                    B.Tech
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li class="dropdown-submenu">
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={(e) => toggleDropdown(e, 3)}
                              >
                                School of Pharmacy
                              </a>
                              <ul class="submenu dropdown-menu level-3">
                                <li>
                                  <a class="dropdown-item" href="#">
                                    B.Pharm
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#">
                                    D.Pharm
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#">
                                    M.Pharm
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li class="dropdown-submenu">
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={(e) => toggleDropdown(e, 3)}
                              >
                                School of Commerce and Management
                              </a>
                              <ul class="submenu dropdown-menu level-3">
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="https://jssuninoida.edu.in/assets/docs/Rev_01_JSS_University_Noida_MCA_Examination_regulations.pdf"
                                  >
                                    MCA
                                  </a>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <a class="dropdown-item" href="#">
                                PH.D
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            NAD
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            ABC ID
                          </a>
                        </li>

                        <li class="dropdown-submenu">
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            Results &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                href="https://studentportal.universitysolutions.in/index.html"
                              >
                                Result
                              </a>
                            </li>

                            <li>
                              <a
                                class="dropdown-item"
                                href="https://jssuninoida.edu.in/assets/docs/EXAM_GRIVENCE_FORM.docx"
                                target="_blank"
                              >
                                Examination Grievance Form
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Examination manual
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Convocation
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Contact us
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Facilities
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a
                          class="dropdown-item"
                          href="/library-information-centret"
                        >
                          Library & Information Centre
                        </a>
                        <a class="dropdown-item" href="/hostel-facilities">
                          Hostel
                        </a>
                        <a class="dropdown-item" href="/sports-facilities">
                          Sports
                        </a>
                        <a class="dropdown-item" href="/medical-facilities">
                          Medical Centre
                        </a>
                        <a class="dropdown-item" href="/amenities-center">
                          Amenities Centre
                        </a>
                        <a class="dropdown-item" href="/guest-house">
                          Guest House
                        </a>
                        <a class="dropdown-item" href="/bank-atm">
                          Bank ATM
                        </a>
                        <a class="dropdown-item" href="/canteen">
                          Canteen
                        </a>
                      </div>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Student Support
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/scholarships">
                          Scholarships
                        </a>
                        <a class="dropdown-item" href="/mentoring">
                          Mentoring Scheme
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Fee Payment
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          NPTEL
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Infosys Springboard
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Cultural Team
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Student Clubs
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Accidental Insurance Scheme
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Meritortious Awards
                        </a>
                      </div>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Research & Innovation
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/PhD_Admission_Notification.pdf"
                            target="_blank"
                          >
                            PhD Admission Notification
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/PhD_REGULATIONS.pdf"
                            target="_blank"
                          >
                            PhD Regulation
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/PhD_Guides_List.pdf"
                            target="_blank"
                          >
                            PhD Guide List
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/Phd_Research_Form_3.pdf"
                            target="_blank"
                          >
                            Form for Guide/Co-Guide (Form-3)
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/PhD_Research_Form_1.pdf"
                            target="_blank"
                          >
                            Form for Admission to PhD Program (Form-1)
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/research-facilities">
                            Research Centres and Facilities
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/step-info">
                            STEP and Startups in Campus
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link"
                        href="/placement-statistics"
                        role="button"
                        // data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        Training & Placement
                      </a>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        More
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a class="dropdown-item" href="/iqac">
                            IQAC
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/photo-gallery">
                            Photo Gallery
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://forms.gle/dsApqfUTFfht5G7R7"
                            target="_blank"
                          >
                            Online Grievance
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/media-coverage">
                            Media Coverage
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Career Oppurtunity
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/ads-archive">
                            Ads Archives
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link"
                        href="/admission-helpdesk-contact"
                        role="button"
                        // data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        Contact Us
                      </a>
                      {/* <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/page-under-development">
                          Alumni
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Photo Gallery
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Online Grievance System
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Media Coverage
                        </a>
                      </div> */}
                    </li>
                  </ul>
                </div>
              </nav>
              {/* </div> */}
            </div>
          )}
        </header>
      </div>
    </>
  );
}
