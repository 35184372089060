import React, { useState, useEffect } from "react";

export default function Notifications() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        marginTop:
          windowWidth < 768 ? "50px" : windowWidth < 1300 ? "150px" : "300px",
        marginBottom: "50px",
      }}
    >
      <h1 style={{ textAlign: "center", color: "#162544" }}>Notifications</h1>
      <ul
        style={{
          listStyleType: "disc",
          paddingLeft: "20px",
          marginTop: windowWidth < 768 ? "10px" : "20px",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        <li style={{ padding: "15px 0" }}>
          Declaration of results for Phase I Semester End Examination (SEE) for
          B.Tech and B.Pharm
        </li>
        <li style={{ padding: "15px 0" }}>
          Concerning the amendments in the final schedule for Phase II SEE,
          there is a change in the duration of examination for courses JCA 106
          and JCA 107.
        </li>
        <li style={{ padding: "15px 0" }}>
          Final schedule for Phase II Semester End Examination (SEE) for MCA
          programs, Academic session 2024-25.
        </li>
        <li style={{ padding: "15px 0" }}>
          Tentative schedule for Phase II Semester End Examination (SEE) for MCA
          programs, Academic session 2024-25.
        </li>
        <li style={{ padding: "15px 0" }}>
          Regarding submission of online examination form through the students'
          EMS portal
        </li>
        <li style={{ padding: "15px 0" }}>
          Final schedule for Phase I Semester End Examination (SEE) for B.Tech
          and B.Pharm programs, Academic session 2024-25.
        </li>
        <li style={{ padding: "15px 0" }}>
          Tentative Schedule for Phase 1 Semester End Examination (SEE) for
          B.Tech and B.Pharm Programs , Academic Sessions 2024-25
        </li>
      </ul>
    </div>
  );
}
