import React, { useState, useEffect } from "react";

export default function Circulars() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        marginTop:
          windowWidth < 768 ? "50px" : windowWidth < 1300 ? "150px" : "300px",
        marginBottom: "50px",
      }}
    >
      <h1 style={{ textAlign: "center", color: "#162544" }}>
        Examination Circulars
      </h1>
      <ul
        style={{
          listStyleType: "disc",
          paddingLeft: "20px",
          marginTop: windowWidth < 768 ? "10px" : "20px",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        <li style={{ padding: "15px 0" }}>
          <a
            target="_blank"
            href="https://jssuninoida.edu.in/assets/circulars/Amendment_in_MCA_Odd_Semester_Exam_Schedule.pdf"
          >
            Concerning the amendments in the final schedule for Phase II SEE,
            there is a change in the duration of examination for courses JCA 106
            and JCA 107.
          </a>
        </li>
        <li style={{ padding: "15px 0" }}>
          <a
            target="_blank"
            href="https://jssuninoida.edu.in/assets/circulars/Final_Schedule_Phase_2_MCA.pdf"
          >
            Final schedule for Phase II Semester End Examination (SEE) for MCA
            programs, Academic session 2024-25.
          </a>
        </li>
        <li style={{ padding: "15px 0" }}>
          <a
            target="_blank"
            href="https://jssuninoida.edu.in/assets/circulars/Tentative_Schedule_Phase_2_MCA.pdf"
          >
            Tentative schedule for Phase II Semester End Examination (SEE) for
            MCA programs, Academic session 2024-25.
          </a>
        </li>
        <li style={{ padding: "15px 0" }}>
          <a
            target="_blank"
            href="https://jssuninoida.edu.in/assets/circulars/Submission_of_Examination_Form_EMS.pdf"
          >
            Regarding submission of online examination form through the
            students' EMS portal
          </a>
        </li>
        <li style={{ padding: "15px 0" }}>
          <a
            target="_blank"
            href="https://jssuninoida.edu.in/assets/circulars/Final_Schedule_Phase_1_BTech_BPharma.pdf"
          >
            Final schedule for Phase I Semester End Examination (SEE) for B.Tech
            and B.Pharm programs, Academic session 2024-25.
          </a>
        </li>
        <li style={{ padding: "15px 0" }}>
          <a
            target="_blank"
            href="https://jssuninoida.edu.in/assets/circulars/Tentative_Schedule_Phase_1_BtechBPharma.pdf"
          >
            Tentative Schedule for Phase 1 Semester End Examination (SEE) for
            B.Tech and B.Pharm Programs , Academic Sessions 2024-25
          </a>
        </li>

       
      </ul>
    </div>
  );
}
