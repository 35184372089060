import "./App.css";
import Chancellor from "./components/Chancellor/Chancellor";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Homepage from "./components/Homepage/Page";
import Banner from "./components/Homepage/components/Banner";
import Campusvideo from "./components/Homepage/components/Campusvideo";
import HomepageCarousel from "./components/Homepage/components/HomepageCarousel";
import Newssection from "./components/Homepage/components/Newssection";
import Notification from "./components/Homepage/components/Notifications";
import Recruiter from "./components/Homepage/components/Recruiter";
import AboutJSSMVP from "./components/AboutJSSMVP/AboutJSSMVP";
import AboutUniversity from "./components/AboutUniversity/AboutUniversity";
import AboutProChancellor from "./components/ProChancellor/ProChancellor";
import PopupButtons from "./components/PopupButtons";
import CustomNotFound from "./components/CustomNotFound";
import CustomUnderConstruction from "./components/CustomUnderConstruction";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SchoolOfEngineering from "./components/Courses/SchoolOfEngineering";
import SchoolOfPharmacy from "./components/Courses/SchoolOfPharmacy";
import SchoolOfManagement from "./components/Courses/SchoolOfManagement";
import SchoolOfLifesciences from "./components/Courses/SchoolOfLifesciences";
import SchoolOfAppliedSciences from "./components/Courses/SchoolOfAppliedSciences";
import Medical from "./components/Facilities/Medical";
import Hostel from "./components/Facilities/Hostel";
import Sports from "./components/Facilities/Sports";
import WhatsappButton from "./components/WhatsappButton";
import ThankYouAdmissionQuery from "./components/ThankYouAdmisionQuery";
import PlacementStatistics from "./components/Placement/PlacementStatistics";
import JSSLoader from "./components/JSSLoader";
import Es from "./components/es/es";
import ApplyForAdmission from "./components/Admission/ApplyForAdmission";
import GreenPolicy from "./components/GreenInitiatives/GreenPolicy";
import SewageTreatmentPlant from "./components/GreenInitiatives/SewageTreatmentlPlant";
import RooftopSolarPowerPlant from "./components/GreenInitiatives/RooftopSolarPlant";
import RainwaterHarvesting from "./components/GreenInitiatives/RainwaterHarvesting";
import SustainableWasteManagement from "./components/GreenInitiatives/SolidWasteManagement";
import GardeningTreePlantation from "./components/GreenInitiatives/TreePlantation";
import BankATMFacility from "./components/Facilities/BankATM";
import GuestHouse from "./components/Facilities/GuestHouse";
import AmenitiesCenter from "./components/Facilities/AmenitiesCenter";
import Canteen from "./components/Facilities/Canteen";
import LibraryInformationCentre from "./components/Facilities/LibraryInformationCentre";
import AboutEmployeeBenefits from "./components/AboutEmployeeBenefits/AboutEmployeeBenefits";
import AboutVisionMissionPolicy from "./components/VisionMission/VisionMission";
import AdmissionHelpDesk from "./components/Admission/AdmisionHelpDesk";
import AdmissionMain from "./components/Admission/AdmissionMain";
import SchoolOfHumanities from "./components/Courses/SchoolOfHumanities";
import Scholarships from "./components/Scholarship/Page";
import Mentoring from "./components/Mentoring/Page";
import ResearchFacilities from "./components/Research/Page";
import AdmissionCriteria from "./components/Admission/AdmissionCriteria";
import FeeStructure from "./components/Admission/FeeStructure";
import ChancellorMessage from "./components/Messages/MessageChancellor";
import Iqac from "./components/IQAC/Page";
import ProChancellorMessage from "./components/Messages/MessageProChancellor";
import ViceChancellorMessage from "./components/Messages/MessageViceChancellor";
import STEPInfo from "./components/STEPInfo/STEPInfo";
import GoverningBodies from "./components/Authorities/GoverningBodies";
import AdmissionChecklist from "./components/Admission/AdmissionChecklist";
import StatutoryBodies from "./components/StatutoryBodies";
import ViceChancellor from "./components/Administration/ViceChancellor";
import ControllerOfExamination from "./components/Administration/ControllerOfExamination";
import CSE from "./components/EnggCourses/CSE";
import CSEAIML from "./components/EnggCourses/CSEAIML";
import CSEDS from "./components/EnggCourses/CSEDS";
import IT from "./components/EnggCourses/IT";
import PharmacyDetail from "./components/PharmacyCourses/PharmacyDetail";
import RegistrarMessage from "./components/Messages/MessageRegistrar";
import ExecutiveCouncil from "./components/Authorities/ExecutiveCouncil";
import FacultyOfEnggTech from "./components/Faculty/FacultyOfEnggTech";
import FacultyOfScience from "./components/Faculty/FacultyOfScience";
import FacultyOfHumanities from "./components/Faculty/FacultyOfHumanities";
import MediaCoverage from "./more/MediaCoverage";
import IEEEDetail from "./components/ProfessionalBodies/IEEEDetail";
import IETEDetail from "./components/ProfessionalBodies/IETEDetail";
import ISTEDetail from "./components/ProfessionalBodies/ISTEDetail";
import SAEDetail from "./components/ProfessionalBodies/SAEDetail";
import CSIDetail from "./components/ProfessionalBodies/CSIDetail";
import PhotoGallery from "./components/More/PhotoGallery";
import AdsArchive from "./more/AdsArchive";
import CalenderOfEvents from "./components/Academics/CalenderOfEvents";
import UniversityNewsLetter from "./components/Academics/UniversityNewsLetter";
import Notifications from "./components/Notifications/Notifications";
import Circulars from "./components/ExaminationCirculars/Circulars";
function App() {
  return (
    <Router>
      <Header />
      <PopupButtons />
      <Routes>
        <Route path="/" element={<JSSLoader />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/chancellor" element={<Chancellor></Chancellor>} />
        <Route path="/about-jssmvp" element={<AboutJSSMVP></AboutJSSMVP>} />
        <Route
          path="/about-university"
          element={<AboutUniversity></AboutUniversity>}
        />
        <Route
          path="/about-pro-chancellor"
          element={<AboutProChancellor></AboutProChancellor>}
        />
        <Route
          path="/school-of-engineering"
          element={<SchoolOfEngineering></SchoolOfEngineering>}
        />
        <Route
          path="/school-of-pharmacy"
          element={<SchoolOfPharmacy></SchoolOfPharmacy>}
        />
        <Route
          path="/school-of-humanities"
          element={<SchoolOfHumanities></SchoolOfHumanities>}
        />
        <Route
          path="/school-of-management"
          element={<SchoolOfManagement></SchoolOfManagement>}
        />
        <Route
          path="/school-of-life-sciences"
          element={<SchoolOfLifesciences></SchoolOfLifesciences>}
        />
        <Route
          path="/school-of-applied-sciences"
          element={<SchoolOfAppliedSciences></SchoolOfAppliedSciences>}
        />
        <Route path="/medical-facilities" element={<Medical></Medical>} />
        <Route path="/hostel-facilities" element={<Hostel></Hostel>} />
        <Route path="/sports-facilities" element={<Sports></Sports>} />
        <Route
          path="/thank-you-admission"
          element={<ThankYouAdmissionQuery />}
        />
        <Route path="/executive-seceratory" element={<Es></Es>} />
        <Route path="/admission-apply" element={<ApplyForAdmission />} />
        <Route path="/green-policy" element={<GreenPolicy />} />
        <Route
          path="/sewage-treatment-plant"
          element={<SewageTreatmentPlant />}
        />
        <Route
          path="/rooftop-solar-plant"
          element={<RooftopSolarPowerPlant />}
        />
        <Route path="/rainwater-harvesting" element={<RainwaterHarvesting />} />
        <Route
          path="/sustainable-waste-management"
          element={<SustainableWasteManagement />}
        />
        <Route path="/tree-plantation" element={<GardeningTreePlantation />} />
        <Route path="/bank-atm" element={<BankATMFacility />} />
        <Route path="/guest-house" element={<GuestHouse />} />
        <Route path="/amenities-center" element={<AmenitiesCenter />} />
        <Route path="/canteen" element={<Canteen />} />
        <Route
          path="/library-information-centre"
          element={<LibraryInformationCentre />}
        />
        <Route path="/employee-benefits" element={<AboutEmployeeBenefits />} />
        <Route
          path="/vision-mission-quality-policy"
          element={<AboutVisionMissionPolicy />}
        />
        <Route
          path="/admission-helpdesk-contact"
          element={<AdmissionHelpDesk />}
        />
        <Route
          path="/admission-main"
          element={<AdmissionMain></AdmissionMain>}
        />
        <Route path="/scholarships" element={<Scholarships></Scholarships>} />
        <Route path="/mentoring" element={<Mentoring></Mentoring>} />
        <Route
          path="/research-facilities"
          element={<ResearchFacilities></ResearchFacilities>}
        />
        <Route path="/admission-criteria" element={<AdmissionCriteria />} />
        <Route path="/admission-checklist" element={<AdmissionChecklist />} />
        <Route path="/fee-structure" element={<FeeStructure />} />
        <Route path="/iqac" element={<Iqac></Iqac>} />
        <Route path="/placement-statistics" element={<PlacementStatistics />} />
        <Route path="/chancellor-message" element={<ChancellorMessage />} />
        <Route
          path="/pro-chancellor-message"
          element={<ProChancellorMessage />}
        />
        <Route
          path="/vice-chancellor-message"
          element={<ViceChancellorMessage />}
        />
        <Route path="/registrar-message" element={<RegistrarMessage />} />
        <Route
          path="/controller-of-examination"
          element={<ControllerOfExamination />}
        />
        <Route path="/cse" element={<CSE />} />
        <Route path="/cseaiml" element={<CSEAIML />} />
        <Route path="/cse-dst" element={<CSEDS />} />
        <Route path="/it" element={<IT />} />
        <Route path="/step-info" element={<STEPInfo />} />
        <Route path="/governing-bodies" element={<GoverningBodies />} />
        {/* <Route path="/executive-council" element={<ExecutiveCouncil />} /> */}
        <Route path="/statutory-bodies" element={<StatutoryBodies />} />
        <Route
          path="/administration-vice-chancellor"
          element={<ViceChancellor />}
        />
        <Route path="/pharmacy-detail" element={<PharmacyDetail />} />
        <Route path="/faculty-of-engg-tech" element={<FacultyOfEnggTech />} />
        <Route path="/faculty-of-science" element={<FacultyOfScience />} />
        <Route
          path="/faculty-of-humanities"
          element={<FacultyOfHumanities />}
        />
        <Route path="/media-coverage" element={<MediaCoverage />} />
        <Route path="/ieee-detail" element={<IEEEDetail />} />
        <Route path="/iete-detail" element={<IETEDetail />} />
        <Route path="/iste-detail" element={<ISTEDetail />} />
        <Route path="/sae-detail" element={<SAEDetail />} />
        <Route path="/csi-detail" element={<CSIDetail />} />
        <Route path="/photo-gallery" element={<PhotoGallery />} />
        <Route path="/ads-archive" element={<AdsArchive />} />
        <Route path="/calendar-of-events" element={<CalenderOfEvents />} />
        <Route
          path="/university-news-letter"
          element={<UniversityNewsLetter />}
        />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/examination-circulars" element={<Circulars />} />

        <Route path="*" element={<CustomNotFound />} />
        <Route
          path="/page-under-development"
          element={<CustomUnderConstruction />}
        />
      </Routes>
      <WhatsappButton />
      <Footer />
    </Router>
  );
}

export default App;
